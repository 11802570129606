//@flow
import React, { useState } from "react"
import { Shaders, Node, GLSL } from "gl-react"
import { Surface } from "gl-react-dom"

const shaders = Shaders.create({
  shader: {
    frag: GLSL`
    
    precision highp float;
    varying vec2 uv;
    uniform float time;
    
    highp float random2d(vec2 co) {
        highp float a = 12.9898;
        highp float b = 78.233;
        highp float c = 43758.5453;
        highp float dt = dot(co.xy, vec2(a, b));
        highp float sn = mod(dt, 3.14);
        return fract(sin(sn) * c);
    }
    
    void main() {
        // Create a grid of squares that depends on the mouse position
        vec2 square = uv.xy;
    
        // Give a random color to each square
        vec3 square_color = vec3(0.0, random2d(1.234 * square), 0.0);
        square_color *= exp(float(random2d(5.883 * square) * cos(random2d(10.4838 * square) * time * 0.4 + time * 1.0 * random2d(3.238 * square)))) - 1.8;
    
        // Fragment shader output
        gl_FragColor = vec4(square_color, 1.0);
    }

    `,
  },
})

const ShaderNode = () => {
  const [time, setTime] = useState(0)
  const loop = (time: number) => {
    requestAnimationFrame(loop)
    setTime(time / 1000)
  }
  requestAnimationFrame(loop)
  return <Node shader={shaders.shader} uniforms={{ time: time }} />
}

const Shader = () => (
  <Surface width={8} height={8}>
    <ShaderNode />
  </Surface>
)

export default Shader
