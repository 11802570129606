import React, { FunctionComponent } from "react"

const KeyPoint: FunctionComponent<{
  i: number
  title: string
  description: string[]
}> = ({ i, title, description }) => (
  <div className="flex flex-row items-center space-x-2">
    <span className="text-primary-100 font-bold text-4xl font-monospace">
      {i}.
    </span>
    <div className="flex flex-col space-y-2">
      <span className="text-primary-100 font-bold font-monospace text-sm">
        {title}
      </span>
      <div className="flex flex-col space-y-1">
        {description.map((s) => (
          <span className="text-xs">{s}</span>
        ))}
      </div>
    </div>
  </div>
)

const Origin = () => (
  <div className="flex flex-col md:px-20 space-y-20">
    <span className="tracking-widest mx-auto font-monospace text-xs">
      ORIGIN
    </span>
    <div className="flex flex-col space-y-8">
      <div className="border-l-2 border-primary-100 border-opacity-50 p-4 text-sm text-gray-200">
        CAPx is an extension of the original{" "}
        <a
          className="transition duration-300 hover:text-primary-100"
          href="https://cap.finance"
        >
          CAP
        </a>{" "}
        protocol. It is not a competitor, but rather tries to improve on it
        through three key points.
      </div>
      <div className="flex flex-col space-y-8">
        <KeyPoint
          i={1}
          title="Fair Distribution"
          description={[
            "$CAPx is issued through a liquidity bootstrapping contract, with a small portion of the total supply - ensuring a fair distribution in the long term.",
            "No community should ever have to endure the pressure of an unfair holder.",
            "The rest of the supply will be sent to the governance, letting the community decide whether to implement incentives such as a liquidity mining program.",
            "Moreover, the $CAPx token has an inbuilt fee mechanism that takes a cut on all transactions and redirects it to the treasury.",
          ]}
        />
        <KeyPoint
          i={2}
          title="Open-source Oracle"
          description={[
            "CAPx will run exclusively on the blockchain, thanks to an open-source oracle implemented with Chainlink.",
            "The new oracle supports all types of assets, including stocks, indices, crypto, ETF and commodities.",
          ]}
        />
        <KeyPoint
          i={3}
          title="Active L2 Support"
          description={[
            "Although $CAPx will be initially distributed on Ethereum, the rest of the protocol runs on Matic (now Polygon), an efficient L2 scaling solution.",
            "This means more transaction throughput and significantly reduced gas fees, making CAPx more accessible and usable for day-to-day trading.",
            "$CAPx will be transferrable from Ethereum to Polygon, and vice-versa, through an ERC20 bridge.",
          ]}
        />
      </div>
    </div>
  </div>
)

export default Origin
