import React, { useEffect, useState } from "react"

import { AiFillClockCircle } from "react-icons/ai"
import { FaTelegramPlane } from "react-icons/fa"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import Shader from "./Shader"

const AssetShowcase = () => {
  const assets = ["AAPL", "BTC", "NFLX", "GOOG", "ETH", "SP500"]
  const [assetIndex, setAssetIndex] = useState(0)
  const asset = assets[assetIndex]
  useEffect(() => {
    const interval = setInterval(
      () => setAssetIndex((assetIndex + 1) % assets.length),
      2000
    )
    return () => {
      clearInterval(interval)
    }
  })

  return (
    <span className="inline-flex relative text-primary-100 align-bottom">
      <SwitchTransition mode="in-out">
        <CSSTransition
          key={asset.toLocaleUpperCase()}
          classNames={{
            enter: "opacity-0",
            enterActive: "mb-8 opacity-0",
            exitActive: "-mb-8 opacity-0",
            exitDone: "opacity-0",
          }}
          addEndListener={(node, done) =>
            node.addEventListener("transitionend", done, false)
          }
        >
          <span className="absolute left-0 bottom-0 text-6xl transform transition-all duration-500">
            {asset}
          </span>
        </CSSTransition>
      </SwitchTransition>
      <div
        className="left-0 bottom-0 text-6xl w-full h-1 bg-primary-100 -mb-1 ml-0.5 opacity-10 transition-all duration-300 delay-500"
        style={{ width: `calc(${asset.length} * 2.25rem)` }}
      />
    </span>
  )
}

const Hero = () => (
  <div className="relative flex flex-col mt-32 mb-32 px-4 md:px-32">
    <div
      className="absolute top-0 left-0 w-full"
      id="shader"
      style={{
        marginTop: "-4rem",
        marginLeft: "-1rem",
        width: "calc(100% + 2rem)",
        height: "calc(100% + 8rem)",
      }}
    >
      <Shader />
    </div>
    <div className="flex flex-col relative space-y-8">
      <span className="text-4xl font-monospace leading-relaxed">
        Trade <br className="sm:hidden" />
        <AssetShowcase />
        <br />
        with DAI stablecoin.
      </span>
      <span className="text-sm opacity-75 max-w-sm">
        CAPx uses an automated market maker to quote asset prices. A liquidity
        pool receives losses and pays out gains.
      </span>
      <div className="flex flex-row font-monospace text-xs tracking-widest justify-between items-center">
        <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-8">
          <a className="flex flex-row w-52 p-4 py-2 border-b border-primary-100 justify-between items-center hover:bg-primary-100 hover:text-black transition duration-500">
            <span>LAUNCHING Q2 2021</span>
            <AiFillClockCircle size="1.4rem" />
          </a>
        </div>
        <a
          className="flex flex-row w-auto w-16 p-2 py-2 border-b border-primary-100 justify-between items-center hover:bg-primary-100 hover:text-black transition duration-500"
          href="https://t.me/capxfinance"
          target="_blank"
        >
          <FaTelegramPlane size="1.4rem" />
        </a>
      </div>
    </div>
  </div>
)

export default Hero
